import React from "react"
import styles from "./textPic.module.styl"

export default props => (
  <p
    style={{ margin: `${props.margin}` }}
    className={styles.text + " " + styles[props.textclass]}
  >
    {props.content}
  </p>
)

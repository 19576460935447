import React from "react"
import styles from "./intro.module.styl"

export default props => {
  return (
    <div className={styles.intro + " " + styles[props.intro_class]}>
      <h3>{props.title}</h3>
      <h4>{props.title_cn}</h4>
      <p className={styles.intro_cn}>{props.intro_cn}</p>
      <p className={styles.intro_en}>{props.intro_en}</p>
    </div>
  )
}

import React from "react"
import styles from "./coverAll.module.styl"
import MBox from "./mBox"

class Cover extends React.Component {
  // 缩放图片
  _zoomPic(img) {
    console.log("没被替换")
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      const Zmage = require("react-zmage-bobo").default

      this._zoomPic = img => {
        let src = "",
          fluidSrc = ""

        if (img.childImageSharp) {
          let srcArr = img.childImageSharp.fluid.srcSet.split(",")
          fluidSrc = srcArr[srcArr.length - 1].split(" ")[0]
        }

        if (typeof img === "string") {
          src = img
        } else {
          src =
            fluidSrc || img.publicURL || img.childImageSharp.fluid.originalImg
        }

        Zmage.browsing({
          src: src,
          directZoom: true,
        })
      }
    }
  }

  render() {
    const props = this.props

    const newprops = {
      img: {
        src: props.img,
        alt: props.title,
        cdn: props.imgCDN,
        bgcolor: "#000",
        fit: props.fit,
        onClick: () => {
          if (props.zoom) this._zoomPic(props.img || props.imgCDN)
        },
      },
      video: {
        src: props.video,
        vid: props.vid,
        poster: props.poster,
        fit: props.fit,
      },
    }

    return (
      <a
        className={styles.cover + " " + styles[props.coverClass]}
        index={props.index}
        href={props.href}
        style={{
          cursor: props.zoom ? "zoom-in" : "",
        }}
      >
        <MBox {...newprops} />

        <div className={styles.text}>
          <div className={styles.title} style={{ color: props.color }}>
            {props.title}
          </div>
        </div>
      </a>
    )
  }
}

export default Cover

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Intro from "../components/intro"
import Cover from "../components/coverAll"
import Text from "../components/text"
import TextPic from "../components/textPic"
import MBox from "../components/mBox"
import SEO from "../components/seo"
import styles from "./project.module.styl"

class Project extends React.Component {
  render() {
    const data = this.props.data
    const project = data.projectsYaml
    const content = project.intro_cn + "\n\n" + project.intro_en

    const renderFilter = (props, idx) => {
      let jsx = ""
      const newprops = {
        img: {
          src: props.img,
          alt: props.title,
          cdn: props.imgCDN,
          fit: props.fit,
        },
        video: {
          src: props.video,
          vid: props.vid,
          poster: props.poster,
          ratio: props.ratio,
          zoom: props.zoom,
          vclass: props.vclass,
          type: props.type,
          fit: props.fit,
        },
      }

      if (props.text) {
        jsx = (
          <Text
            content={props.text}
            textclass={props.textclass}
            margin={props.margin}
            key={idx}
          />
        )
      } else if (props.video || props.vid) {
        jsx = (
          <div key={idx} style={{ position: "relative" }}>
            <MBox {...newprops} />
          </div>
        )
      } else if (props.textPic) {
        jsx = (
          <TextPic
            align={props.align}
            margin={props.margin}
            img={props.textPic}
            key={idx}
          />
        )
      } else if (props.img || props.imgCDN) {
        jsx = (
          <div key={idx} style={{ position: "relative" }}>
            <Cover {...props} coverClass="noshade" zoom={true} fit="default" />
          </div>
        )
      }

      return jsx
    }

    return (
      <Layout mode="mode_black">
        <SEO
          title={project.title}
          description={content}
          pathname={project.fields.slug}
        />
        <main className={styles.main}>
          <Intro
            title={project.title}
            title_cn={project.title_cn}
            intro_cn={project.intro_cn}
            intro_en={project.intro_en}
            intro_class={project.intro_class}
          />
          {project.albums.map((obj, index) => renderFilter(obj, index))}
        </main>
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    projectsYaml(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      title
      title_cn
      intro_cn
      intro_en
      intro_class
      albums {
        index
        img {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
              originalImg
            }
          }
          publicURL
        }
        ratio
        zoom
        imgCDN
        video {
          size
          publicURL
        }
        poster
        type
        vid
        vclass
        fit
        className
        text
        textclass
        textPic {
          childImageSharp {
            fixed(height: 60) {
              ...GatsbyImageSharpFixed
            }
          }
          publicURL
        }
        align
        margin
      }
    }
  }
`
export default Project

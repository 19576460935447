import React from "react"
import Img from "gatsby-image"
import styles from "./textPic.module.styl"

export default props => {
  const style = {
    position: "relative",
    display: "flex",
    justifyContent: props.align,
    whiteSpace: "pre-line",
    fontSize: "0.87rem",
  }
  return (
    <div style={style} className={styles.textpic}>
      <Img
        fixed={props.img.childImageSharp.fixed}
        style={{ margin: `${props.margin}` }}
        imgStyle={{ alianSelf: "flex-end" }}
      />
    </div>
  )
}
